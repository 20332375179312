import React from 'react';
import { Box } from 'theme-ui';

import RenderSideList from './RenderSideList';
import RenderSectionForSideNav from './RenderSectionForSideNav';

const SideNavSections = ({ pageBody: { sideNavSliderItems } }) => {
  return (
    <Box sx={slideNavSection}>
      <RenderSideList sideNavSliderItems={sideNavSliderItems} />
      <RenderSectionForSideNav sideNavSliderItems={sideNavSliderItems} />
    </Box>
  );
};

export default SideNavSections;

const slideNavSection = {
  position: 'relative',
};
